import React from "react";
import ReactTooltip from "react-tooltip";
import type { PlanType } from "../types";
const Plan = ({ data }: { data: PlanType }) => {
  const { btn, description, features, title } = data;

  return (
    <div className="bg-[#f9f9f9] p-4 flex flex-col gap-4 basis-[50%] grow-0">
      <ReactTooltip place="top" class="tooltip" backgroundColor="#000" />
      <div className="flex flex-col gap-2 pb-4 text-center border-b border-b-hn-muted border-opacity-10">
        <h3 className="text-xl font-bold dsc-h3">{title}</h3>
      </div>
      <div className="space-y-4">
        <ul className="flex flex-col gap-4 mt-4 ml-8 list-disc">
          {features?.map((fet) => (
            <li key={fet.id} className="dsc-text-sm">
              {fet.description}
            </li>
          ))}
        </ul>
        <h4 className="my-6 dsc-text">{description}</h4>
      </div>
      <a href={"/contact-us"}>
        <span className="w-[144px] h-[38px] mx-auto mt-2 grid place-items-center mb-6  text-white rounded-full bg-dsc-theme-primary hover:bg-hn-primary-hover transition-all duration-300">
          {btn}
        </span>
      </a>
    </div>
  );
};
export default Plan;
