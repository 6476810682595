import React from "react";
import Table from "./table";
import Card from "./card";
const PackageData = [
  {
    active: 1,
    btn: "お問い合わせ",
    cta: "コストバジェ",
    description:
      "Rundeck Communityは、小規模なチーム向けに構築されています。非本番用のセルフサービスを解決するためのRunbook Automationの技術的な複雑さに対処します。",
    features: [
      { id: 1, description: "Rundeckのオープンソースコア" },
      { id: 2, description: "少人数のチームでの使用に適しています" },
      { id: 3, description: "コミュニティフォーラムのサポートのみ" },
      {
        id: 4,
        description:
          "統合のためにCommunity Plugins（Ansible、Jenkins、Kubernetesステップ、ノードエグゼキューターなど）のみをサポートします",
      },
    ],
    title: "Rundeck Community",
  },
  {
    active: 2,
    btn: "お問い合わせ",
    cta: "コストバジェ",
    description:
      "Rundeck Enterpriseは、大規模なチームや組織を対象としています。セルフサービス、インシデント対応、コンプライアンスおよび監査機能を使用して、チームおよび複数のクラスターが抱える組織の複雑さの問題に対処します。",
    features: [
      {
        id: 1,
        description:
          "スクリプト、ツール、API、コマンドを横断したワークフローの作成",
      },
      { id: 2, description: "オンデマンドで利用できるOpsアクション" },
      { id: 3, description: "監査用のビルトインアクティビティログ" },
      { id: 4, description: "既存のエコシステムにプラグイン可能" },
      {
        id: 5,
        description:
          "統合用のコミュニティプラグインに加えて、認定エンタープライズプラグイン（PagerDuty、ServiceNow、Datadog、VMWare、Github、AWS SNS、SQL Runnerなど）をサポートします",
      },
      {
        id: 6,
        description: "24時間年中無休のテクニカルサポートをご利用いただけます",
      },
    ],
    title: "Rundeck Enterprise",
  },
];
const TableData = [
  {
    title: "サポート",
    features: [
      {
        id: 1,
        title:
          "Enterpriseサポート（優先度ベースのサポート、アカウント管理、SLA応答など）",
        free: "false",
        team: "true",
      },
    ],
    thead: {
      name: "サポート",
      title1: "Community",
      title2: "Enterprise",
    },
  },
  {
    title: "回復力",
    features: [
      {
        id: 1,
        title:
          "Runbook Automationが継続的に動作することを保証する高可用性クラスター",
        free: "false",
        team: "true",
      },
      {
        id: 2,
        title: "失敗したジョブの再開",
        free: "false",
        team: "true",
      },
      {
        id: 3,
        title: "Nodeのヘルスチェック",
        free: "false",
        team: "true",
      },
      {
        id: 4,
        title:
          "自動テイクオーバー、クラスターメンバーがダウンした場合、そのメンバーでスケジュールされているすべてのジョブが正常なインスタンスに移されます",
        free: "false",
        team: "true",
      },
    ],
    thead: {
      name: "サポート",
      title1: "Community",
      title2: "Enterprise",
    },
  },
  {
    title: "セキュリティ",
    features: [
      {
        id: 1,
        title: "シングルサインオン認証",
        free: "false",
        team: "true",
      },
      {
        id: 2,
        title: "キーとパスワードの暗号化",
        free: "false",
        team: "true",
      },
    ],
    thead: {
      name: "サポート",
      title1: "Community",
      title2: "Enterprise",
    },
  },
  {
    title: "より深いソリューションの統合",
    features: [
      {
        id: 1,
        title:
          "認定エンタープライズプラグイン（PagerDuty、ServiceNow、Datadog、VMWare、Github、AWS SNS、SQL Runnerなど）",
        free: "false",
        team: "true",
      },
      {
        id: 1,
        title: "ルールベースの処理やインプロダクトデバッグを含む高度なWebhook",
        free: "false",
        team: "true",
      },
    ],
    thead: {
      name: "サポート",
      title1: "Community",
      title2: "Enterprise",
    },
  },
  {
    title: "スケーラビリティ",
    features: [
      {
        id: 1,
        title:
          "特定のタイプのトラフィックまたは特定のタイプのジョブを処理するクラスターメンバーを指定することにより、Rundeckクラスターを水平方向にスケーリングする負荷分散",
        free: "false",
        team: "true",
      },
      {
        id: 2,
        title: "ユーザビリティ",
        free: "false",
        team: "true",
      },
      {
        id: 3,
        title: "ワークフローの視覚化",
        free: "false",
        team: "true",
      },
      {
        id: 4,
        title: "Access Control Rules BuilderとEvaluatorを介したGUIベースのACL",
        free: "false",
        team: "true",
      },
    ],
    thead: {
      name: "サポート",
      title1: "Community",
      title2: "Enterprise",
    },
  },
  {
    title: "スケジューリング",
    features: [
      {
        id: 1,
        title: "ジョブに依存しないスケジューリング",
        free: "false",
        team: "true",
      },
      {
        id: 2,
        title: "ブラックアウトカレンダー",
        free: "false",
        team: "true",
      },
      {
        id: 3,
        title: "スケジュール予測の視覚化",
        free: "false",
        team: "true",
      },
    ],
    thead: {
      name: "サポート",
      title1: "Community",
      title2: "Enterprise",
    },
  },
  {
    title: "コア機能",
    features: [
      {
        id: 1,
        title: "ワークフローの実行",
        free: "true",
        team: "true",
      },
      {
        id: 2,
        title: "Communityプラグイン",
        free: "true",
        team: "true",
      },
      {
        id: 3,
        title:
          "セルフサービス（プロビジョニング、セキュリティ、ソフトウェアの更新/展開、構成の変更、ポートのオープン、インシデント管理など）",
        free: "true",
        team: "true",
      },
      {
        id: 4,
        title: "基本的なスケジューリング",
        free: "true",
        team: "true",
      },
      {
        id: 5,
        title: "ジョブアクティビティログ",
        free: "true",
        team: "true",
      },
      {
        id: 6,
        title: "Key Storage",
        free: "true",
        team: "true",
      },
    ],
    thead: {
      name: "サポート",
      title1: "Community",
      title2: "Enterprise",
    },
  },
];
const PricingComponent = () => {
  return (
    <div className="mb-12 my-[80px] md:my-[96px] ">
      <h1 className="font-bold text-center dsc-h1">価格</h1>
      <h2 className="font-bold text-center dsc-h2">
        御社のビジネスに最適なプランをお選びください
      </h2>

      <div className="flex flex-col justify-center gap-8 px-4 md:px-0 md:flex-row mt-[96px]">
        {PackageData.map((pack, index) => (
          <Card key={pack.title} data={pack} />
        ))}
      </div>
      <div className="flex flex-col mt-[96px] px-4 md:px-0">
        <h3 className="font-bold text-center dsc-h3">各プランの詳細</h3>
        {TableData.map((_, index) => (
          <Table key={index} data={_}></Table>
        ))}
      </div>
    </div>
  );
};
export default PricingComponent;
