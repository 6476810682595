import React from "react";
import type { TableType } from "../types";
const isValid = (bol: string) => {
  if (bol === "true") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-6 h-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="3"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M5 13l4 4L19 7"
        />
      </svg>
    );
  } else if (bol === "false") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-6 h-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="red"
        stroke-width="3"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    );
  } else {
    return bol;
  }
};

const Table = ({ data }: { data: TableType }) => {
  const { title, features, thead } = data;
  const evenOdd = (id) => {
    return id % 2 !== 0;
  };
  return (
    <>
      <h3 className="my-4 font-bold text-dsc-theme-accent">{title}</h3>
      <div className="flex flex-col w-full overflow-x-auto">
        <table className="w-full table-fixed">
          <thead className="w-full text-center border-b">
            <tr className="bg-dsc-theme-accent/5 dsc-text">
              <th className=" p-4 w-[200px]  w-[150px] md:w-[400px]">
                {/* {thead.name} */}
              </th>
              <th className="p-4 w-[200px]">{thead.title1}</th>
              <th className="p-4 w-[200px]">{thead.title2}</th>
              {/* <th className="p-4 w-[200px]">{thead.title3}</th> */}
            </tr>
          </thead>
          <tbody className="">
            {features.map((feature, index) => (
              <tr
                key={feature.id}
                className="text-center border-b hover:bg-dsc-theme-accent/5 dsc-text-sm"
                style={
                  evenOdd(index)
                    ? {
                        backgroundColor: " rgba(251,95,71,0.05)",
                      }
                    : {}
                }
              >
                <td className="text-left border-b ">{feature.title}</td>
                <td className="p-4 [&>svg]:mx-auto">{isValid(feature.free)}</td>
                <td className="p-4  [&>svg]:mx-auto">
                  {isValid(feature.team)}
                </td>
                {/* <td className="p-4  [&>svg]:mx-auto">
                  {isValid(feature.enterprise)}
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default Table;
