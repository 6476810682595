import React from "react";
import Table from "../../components/pricing";
import Meta from "../../components/common/meta";

import Container from "../../layout/container";

const PricingPage = () => {
  const defaultMeta = {
    title: "価格｜Rundeck正規販売代理店 DXable",
    description:
      "Rundeckは、インシデント復旧時間とコストの大幅削減を実現するランブックオートメーションツールです。スクリプトやcronによる定型作業のランブック登録で、誰でも安全にタスクを実行できます。",
    url: "/pricing",
  };
  return (
    <Container>
      <Meta meta={defaultMeta} />
      <div className="md:px-8">
        <Table />
      </div>
    </Container>
  );
};
export default PricingPage;
